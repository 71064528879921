/* Custom cursor styles */
.cursor {
  position: fixed;
  width: 20px;
  /* Smaller size to start */
  height: 20px;
  border-radius: 50%;
  background-color: white;
  pointer-events: none;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  color: black;
  transition: transform 0.2s ease, width 0.2s ease, height 0.2s ease;
  transform: translate(-50%, -50%);
}

.cursor--hover {
  width: 100px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.8);
}

/* Text inside the custom cursor */
.cursor-text {
  font-size: 10px;
  text-align: center;
}

/* Image and video content zooms in when hovered */
.group:hover img,
.group:hover video {
  transform: scale(1.1);
  transition: transform 0.4s ease-in-out;
}